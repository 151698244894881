import React from 'react';

const Footer = () => {

    return (
        <footer className='footer' >
            <div className='innerFooter'>
                <p>Copyright</p>
                <p>Gilchrist Great Grates </p>
                <p>2021</p>
            </div>
        </footer >
    )
}


export default Footer;