import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { HashRouter as Router, withRouter } from "react-router-dom";
import App from "./App";
import "./sass/index.scss";
// import './index.css';

// const AppWithRouter = withRouter(App);


ReactDOM.render(
  <Router >
    <App />
  </Router>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
